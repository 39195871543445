import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import future from "../../../assets/images/blog/the-future.png"

export default () => 
<div>
    <SEO title={'The Future of Customer Relationship is Remote | ICON Worldwide'} 
    description="Think community - not customer - to build a customer relationship based on expertise: discover how on our blog!"
    canonical={'https://icon-worldwide.com/blog/the-future-of-your-customer-relationship'}/>

    <NewsHeader/>

    <div id="single-news">
    <img src={future} style={{width:'100%', display:'block', margin:'0 0 30px'}} alt=" customer relationship, ICON Worldwide Blog, remote" title="ICON Worldwide Blog, remote, customer relationship"/>
        <h1>The Future of <span>Customer Relationships</span> is Remote</h1>
        <div id="title-underline"></div>
        <div id="single-news-texts">
            <ul>
                <li><h2>Think Community, not customer to improve relationships based on expertise not commerce</h2></li>
                <li><h3 className="big-h3">Virtual Conferences will improve the reach of your content</h3></li>
            </ul>
            <p>While most of us have successfully transitioned to working from the home office,  the end of face-to-face meetings is the next challenge.  With most business relationships relying on personal interaction, and face to face, <strong>how can we create and sustain customer relationships when we cannot meet in person?</strong></p>
            <p>What is clear is that much of the business infrastructure behind those personal interactions is gone.</p>
            <p><h4 className="inline"><strong>Reduced Business Travel:</strong></h4>  Large and mid sized companies are cutting business travel for non-essential meetings, both as a health and a cost-saving measure. In addition, many people will simply choose to avoid non-essential travel for the next few years.</p>
            <p><h4 className="inline"><strong> Cancelled Customer Events:</strong></h4>  The private events, that power key-customer relationship for industries like Wealth Management and Banking will have to be reinvented in the immediate future as customers will no longer want to fly, stay in hotels, or meet in groups.</p>
            <p><h4 className="inline"><strong>Trade Shows:</strong></h4> Industry conferences, which support the knowledge sharing and networking for industries like Tech and Pharma will also have to be reimagined.</p>
            <p>ICON Worldwide is developing strategies across various business sectors to deal with this immediate need.  Together with our clients, new concepts are being developed to help meet this challenge.</p>
            <h2>Think Community, Not Customer</h2>
            <p>Your customers all have one thing in common, they choose to work with you to provide a service or product. You already invest a lot of time and effort providing extra value and insight to support this business relationship.</p>
            <p>Private, branded communities connect your customers to each other, and provide an excellent way to connect, provide expertise and build relationships.</p>
            <p>Importantly, a customer community is not built for selling.  It’s a forum to engage with your clients on topics related to your business relationship.  Communities are ideal to reinforce your expertise and foster the client - customer relationship and journey, while sharing leadership knowledge with your clients.</p>
            <p>An insurance company, for example, may create a forum for decision makers to discuss current events with a key thought-leader from the company.  In the process, two customers from very different sectors may connect and share new ideas.</p>
            <p><Link to="/works/project-firefly">Case Study: Learn more about Project-Firefly, a community for students sponsored by Credit Suisse.</Link></p>
            <h2>Improved Reach of Virtual Conferences</h2>
            <p>Companies have realised the cost savings by eliminating onsite events and Investing just a portion of that savings into more robust digital events will allow them to reach far larger audiences.</p>
            <p>This podcast is a great example of how MIT Technology Review was able to transition to remote events, saving money, and reaching a larger audience:</p>
            <p>Link: <a  target="blank" href="https://digiday.com/media/mit-technology-review-events-streaming-revenue-interactivity-networking/">Digiday Podcast: How MIT Technology Review shifted its largest event to streaming</a></p>
            <p>Combining pre-recorded with live-broadcast speakers allows users to interact with the content before, during and after the event.  Offering chat-room meetings with the speakers and using slack to try to recreate the “hallway” networking effect are just some of the new ideas being brought to virtual conferences.</p>
            <p>ICON client <a  href="https://www.frankfurt-school.de/home" target="blank">The Frankfurt School of Finance and Management</a> converted their largest annual student event into a virtual campus visit, offering tours, presentations and one-one meetings: <a  href="https://bachelor-day.frankfurt-school.de" target="blank">https://bachelor-day.frankfurt-school.de</a></p>
            <p>These are times when digital is challenged to be personal, and great opportunities are rising to meet the challenge.  Some will be long-lasting change, breaking old habits, like senseless travel, and some will be temporary salves.  Looking for ways to add more value to relationships is never a bad exercise.</p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

      <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>

        <NewsFooter previous="zero-party-data" next="security-checklist-for-developers"/>
    </div>

    <Footer noScrollbar="true"/>
</div>